import type { PropsWithChildren } from 'react';

interface Props {
  bgClassName?: string;
  borderClassName?: string;
}

export function Box({
  children,
  bgClassName: bgColor = 'bg-cc-300 bg-opacity-50',
  borderClassName: borderColor = 'border-cc-200',
}: PropsWithChildren<Props>) {
  return (
    <div
      className={`w-full rounded-md border ${borderColor} ${bgColor} p-3 shadow-lg backdrop-blur-sm`}
    >
      {children}
    </div>
  );
}
